
import { computed, defineComponent, reactive, ref } from "vue";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import {updateDeviceScenario} from "@/core/services/api/deviceScenario"

export default defineComponent({
  components: {
    Dialog,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    scenarioData: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const dialogShow = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      },
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const formData = reactive({
      name: "",
      description: "",
    });
    const rules = {
      name: [
        { required: true, message: "請輸入裝置情境名稱", trigger: "blur" },
      ],
    };

    const onSubmit = () => {
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要編輯嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                name: formData.name,
                description: formData.description,
              };
              await updateDeviceScenario(props.scenarioData!.data.device,props.scenarioData!.data.guid, request).then(
                async () => {
                  await Swal.fire("編輯成功!", "", "success");
                  emit("onEdit");
                }
              );
            }
          });
        }
      });
    };

    const initFormData = () => {
        console.log("props.scenarioData",props.scenarioData)
      Object.assign(formData, {
        name: props.scenarioData!.name,
        description: props.scenarioData!.description,
      });
    };
    initFormData();

    return {
      ...props,
      dialogShow,
      formData,
      rules,
      formRef,
      onSubmit,
    };
  },
});
