import ApiService from "@/core/services/ApiService";
import { Device } from "@/core/services/responsesModel/device"

// 查詢空間中有的Device
export function getDevices(spaceGuid): Promise<Array<Device>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Space/${spaceGuid}/Device`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 查詢指定裝置
export function getTargetDevice(deviceGuid): Promise<Device> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Device/${deviceGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 修改Device名稱與描述
export function modifyDevice(deviceGuid, request) {
    return new Promise((resolve, reject) => {
        ApiService.patch(`Device/${deviceGuid}`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 重新裝置設置連結
export function resetDevice(deviceGuid) {
    return new Promise((resolve, reject) => {
        ApiService.get(`Device/${deviceGuid}/Reset`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增宇曜裝置到空間
export function addArdomusDevices(spaceGuid, request) {
    return new Promise((resolve, reject) => {
        ApiService.post(`Space/${spaceGuid}/Device/ArdomusDevice`, request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}