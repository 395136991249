
import { computed, defineComponent, reactive, ref } from "vue";
import Dialog from "@/components/v-dialog/CustomizeDialog.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { addDeviceScenario } from "@/core/services/api/deviceScenario";

export default defineComponent({
  components: {
    Dialog,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    deviceGuid: {
      type: String,
      default: "",
    },
  },
  setup(props, { emit }) {
    const dialogShow = computed({
      get: () => props.modelValue,
      set: (val) => {
        emit("update:modelValue", val);
      },
    });
    const formRef = ref<null | HTMLFormElement>(null);
    const formData = reactive({
      name: "",
      description: "",
    });
    const rules = {
      name: [
        { required: true, message: "請輸入裝置情境名稱", trigger: "blur" },
      ],
    };

    const onSubmit = () => {
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                name: formData.name,
                description: formData.description,
              };
              await addDeviceScenario(props.deviceGuid, request).then(
                async () => {
                  await Swal.fire("新增成功!", "", "success");
                  emit("onAdd");
                }
              );
            }
          });
        }
      });
    };

    return {
      ...props,
      dialogShow,
      formData,
      rules,
      formRef,
      onSubmit
    };
  },
});
