import ApiService from "@/core/services/ApiService";

// 取得IoT裝置情境
export function getDeviceScenario(deviceGuid): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
        ApiService.get(`Device/${deviceGuid}/DeviceScenario`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 新增IoT裝置情境
export function addDeviceScenario(deviceGuid,request){
    return new Promise((resolve, reject) => {
        ApiService.post(`Device/${deviceGuid}/DeviceScenario`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 修改IoT裝置情境
export function updateDeviceScenario(deviceGuid,deviceScenarioGuid,request){
    return new Promise((resolve, reject) => {
        ApiService.patch(`Device/${deviceGuid}/DeviceScenario/${deviceScenarioGuid}`,request).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 執行IoT裝置情境
export function operationDeviceScenario(deviceGuid,deviceScenarioGuid){
    return new Promise((resolve, reject) => {
        ApiService.post(`Device/${deviceGuid}/DeviceScenario/${deviceScenarioGuid}/Operation`,{}).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

// 刪除IoT裝置情境
export function deleteDeviceScenario(deviceGuid,deviceScenarioGuid){
    return new Promise((resolve, reject) => {
        ApiService.delete(`Device/${deviceGuid}/DeviceScenario/${deviceScenarioGuid}`).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

