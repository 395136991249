
import { defineComponent, reactive, ref } from "vue";
import { getDeviceScenario } from "@/core/services/api/deviceScenario";
import { useRoute, useRouter } from "vue-router";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { getLocalTimeString } from "@/core/utils/timeString";
import { getTargetDevice } from "@/core/services/api/device";
import AddIoTScenarioDialog from "./components/AddIoTScenarioDialog.vue";
import EditIoTScenarioDialog from "./components/EditIoTScenarioDialog.vue";
import { operationDeviceScenario,deleteDeviceScenario } from "@/core/services/api/deviceScenario";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
    AddIoTScenarioDialog,
    EditIoTScenarioDialog,
  },
  setup() {
    const addScenarioVisible = ref(false);
    const editScenarioVisible = ref(false);
    const route = useRoute();
    const router = useRouter();
    const deviceGuid = route.params.deviceGuid;
    const scenarioList: any = reactive([]);
    const tableData: any = reactive([]);
    const tableHeader = ref([
      {
        name: "名稱",
        key: "name",
        sortable: true,
      },
      {
        name: "描述",
        key: "description",
        sortable: true,
      },
      {
        name: "建立時間",
        key: "createTime",
        sortable: true,
      },
      {
        name: "功能",
        key: "operate",
        sortable: true,
      },
    ]);
    const device: any = reactive({});
    const currentScenario: any = reactive({});

    const setScenarioList = async () => {
      let request = await getDeviceScenario(deviceGuid);
      scenarioList.splice(0,scenarioList.length,...request)
    };

    const setTableData = () => {
      tableData.splice(0);
      for (const item of scenarioList) {
        tableData.push({
          data: item,
          name: item.name,
          description: item.description,
          createTime: getLocalTimeString(item.createTime),
        });
      }
    };

    const setDevice = async () => {
      let request = await getTargetDevice(deviceGuid);
      Object.assign(device, request);
    };

    const onAddScenario = () => {
      addScenarioVisible.value = false;
      init();
    };

    const onEditScenario = () => {
      editScenarioVisible.value = false;
      init();
    };

    const setCurrentScenario = (item) => {
      Object.assign(currentScenario, item);
    };

    const toDetailDataPointCommand = () => {
      // console.log(`scenarioList/detailDataPointCommand/${deviceGuid}/${currentScenario.data.guid}`)
      router.push({
        path: `detailDataPointCommand/${deviceGuid}/${currentScenario.data.guid}/${currentScenario.data.name}`,
      });
    };

    const operation = async () => {
      await operationDeviceScenario(deviceGuid, currentScenario.data.guid).then(
        () => {
          Swal.fire("執行成功!", "", "success");
        }
      );
    };

    const deleteScenario = async () => {
      await Swal.fire({
        title: `您確定要刪除 "裝置情境:${currentScenario.data.name}" 嗎?`,
        confirmButtonText: "確定",
        denyButtonText: `取消`,
      }).then(async() => {
        await deleteDeviceScenario(deviceGuid,currentScenario.data.guid).then(async() => {
          await init();
          Swal.fire("刪除成功!", "", "success");

        })
      })
    };

    const init = async () => {
      await setDevice();
      await setScenarioList();
      console.log("scenarioList", scenarioList);
      setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      device,
      addScenarioVisible,
      deviceGuid,
      onAddScenario,
      setCurrentScenario,
      editScenarioVisible,
      currentScenario,
      onEditScenario,
      toDetailDataPointCommand,
      operation,
      deleteScenario
    };
  },
});
